import { connect } from 'react-redux'
import UserActivityView from '../components/UserActivityView'
import { createActivity, fetchActivityDetail, createUserActivityShare, createActivities } from '../actions/activityDetailAction'
import { updateUserActivity } from '../actions/userInfoAction'

const mapDispatchToProps = dispatch => {
    return {
        onFetchActivityDetail: (userActivityId) => {
          dispatch(fetchActivityDetail(userActivityId))
        },
        onCreateActivity: (userActivityId, date, practiceValue, competitiveValue, isPlanned, flag, comment) => {
          dispatch(createActivity(userActivityId, date, practiceValue, competitiveValue, isPlanned, flag, comment))
        },
        onCreateActivities: (activities, userActivityPubID) => {
          dispatch(createActivities(activities, userActivityPubID))
        },
        onShareUserActivity: (userActivityId, shareToEmail, readOnly) => {
          dispatch(createUserActivityShare(userActivityId, shareToEmail, readOnly))
        },
        onUpdateUserActivity: (userActivityId, name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) => {
          initialLoad = parseInt(initialLoad);
          preDays = parseInt(preDays);
          postDays = parseInt(postDays);
          targetLoad = parseFloat(targetLoad);

          dispatch(updateUserActivity(userActivityId, name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad))
        }
    }
  }

const mapStateToProps = (state = {})  => {
  return {
    activityDetail: state.activityDetail
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserActivityView)