const Actions = Object.freeze(
    {
        UserActivities:
        {
            SET_ACTIVITIES: "USER_ACTIVITIES/SET_ACTIVITIES"
        },
        ActivityDetail:{
            FETCH_ACTIVITY_DETAIL: "ACTIVITY_DETAIL/FETCH_ACTIVITY_DETAIL",
            SET_ACTIVITY_DETAIL_DATA: "ACTIVITY_DETAIL/SET_ACTIVITY_DETAIL_DATA",
            CREATE_ACTIVITY: "ACTIVITY_DETAIL/CREATE_ACTIVITY",
            CREATE_USER_ACTIVITIES: "ACTIVITY_DETAIL/CREATE_USER_ACTIVITIES",
            CREATE_USER_ACTIVITY_SHARE: "ACTIVITY_DETAIL/CREATE_USER_ACTIVITY_SHARE",
            SET_USER_ACTIVITY_SHARE: "ACTIVITY_DETAIL/SET_USER_ACTIVITY_SHARE"
        },
        UserInfo:{
            FETCH_USER_INFO: "USER_INFO/FETCH_USER_INFO",
            SET_USER_INFO: "USER_INFO/SET_USER_INFO",
            
            FETCH_ID_TOKEN_FROM_CODE: "USER_INFO/FETCH_ID_TOKEN_FROM_CODE",
            FETCH_ID_TOKEN_FROM_REFRESH: "USER_INFO/FETCH_ID_TOKEN_FROM_REFRESH",
            SET_ID_TOKEN: "USER_INFO/SET_ID_TOKEN",

            CREATE_USER_ACTIVITY: "USER_INFO/CREATE_USER_ACTIVITY",
            UPDATE_USER_ACTIVITY: "USER_INFO/UPDATE_USER_ACTIVITY",
            DELETE_USER_ACTIVITY: "USER_INFO/DELETE_USER_ACTIVITY",

            ACCEPT_SHARE_INVITATION: "USER_INFO/ACCEPT_SHARE_INVITATION",
            SET_SHARE_INVITATIONS: "USER_INFO/SET_SHARE_INVITATIONS"
        }
    }
)


export { Actions }