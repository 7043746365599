
import React, { useState } from 'react';

function ShareActivity(props) {
    const [shareToEmail, setShareToEmail] = useState();
    const [readOnly, setReadOnly] = useState(false);

    return (<>
        <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Share {props.activityContext.name}</h6>
                </div>
                <div className="ms-panel-body">
                    {props.activityContext.shares.length > 0 ?
                        <div className="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <td>Shared to</td>
                                        <td>View only</td>
                                        <td>State</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.activityContext.shares.map((share) => {
                                        return <tr key={share.PubID}>
                                            <td>{share.SharedTo}</td>
                                            <td>{share.ReadOnly ? "Yes" : "No"}</td>
                                            <td>{share.Pending ? "Pending" : "Active"}</td>
                                            <td><button class="btn btn-secondary float-left">Remove</button></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    :<></>}
                    <form className="needs-validation clearfix" onSubmit={(e) => e.preventDefault()}>
                        <div className="form-row">
                            <div className="col-8">
                                <label htmlFor="share-to-email">Share to email</label>
                                <div className="input-group">
                                    <input type="text" class="form-control" required id="share-to-email" onChange={(e) => setShareToEmail(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="col-4">
                                <div> Read only </div>
                                <label className="ms-switch">
                                    <input type="checkbox" onChange={(e) => {setReadOnly(e.target.checked)}} />
                                    <span className="ms-switch-slider round"></span>
                                </label>
                                
                            </div>
                            
                        </div>
                        <button className="btn btn-success float-right" type="submit" onClick={() => { props.onShare(shareToEmail, readOnly); }}>Share</button>
                        <button className="btn btn-secondary float-left" onClick={props.onClose}>Close</button>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default ShareActivity;