

const createActivity = `
    mutation CreateActivity($userActivityPubId: String!, $time: String!, $practice_value: Float, $competitive_value: Float, $is_planned: Boolean!, $flag: String, $comment: String) {
        addActivity(
            UserActivityPubID: $userActivityPubId, 
            Time: $time, 
            PracticeValue: $practice_value, 
            CompetitiveValue: $competitive_value, 
            IsPlanned: $is_planned,
            Flag: $flag,
            Comment: $comment
        ){
            PubID
        }
    }`;

const createActivities = `
    mutation CreateActivities($activities: [AddActivityRequest]!) {
        addActivities(
            Activities: $activities
        )
    }`;

const createUserActivityShare = `
    mutation CreateUserActivityShare($userActivityPubId: String!, $shareToEmail: String!, $readOnly: Boolean!) {
        addShareInvitation(
            UserActivityPubID: $userActivityPubId,
            ShareToEmail: $shareToEmail,
            ReadOnly: $readOnly
            ){
                PubID
                ReadOnly
                Pending
                SharedTo
            }
        }`;

const getUserActivity = `
 query GetUserActivity($userActivityPubId: ID!) {
    userActivity(PubID: $userActivityPubId){
        PubID
        Name
        MeasureUnit
        MeasureUnitName
        TimeLinePreDays
        TimeLinePostDays
        StartingLoad
        TargetLoad
        ReadOnly
        Activities{
            Time
            PracticeValue
            CompetitiveValue
            IsPlanned
            Flag
            Comment
        }
        Shares{
            PubID
            ReadOnly
            Pending
            SharedTo
        }
    }
}
 `;

export { createActivity, createActivities, getUserActivity, createUserActivityShare }