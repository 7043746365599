import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ActivityGraph from './ActivityGraph.js';
import BreadCrumb from './BreadCrumb';
import PreLoader from './Preloader';
import AddActivity from './AddActivity';
import ShareActivity from './ShareActivity';
import Planner from './Planner';
import AddUserActivity from './AddUserActivity.js';
import { getGraphPoints } from '../graphCalculator.js';


function UserActivityView(props) {
  const {
    activityDetail,
    onFetchActivityDetail
  } = props;

  const { activityID } = useParams()
  const [isAdding, setIsAdding] = useState({ dialogOpen: false, defaults: null });
  const [isSharing, setIsSharing] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [pendingActivities, setPendingActivities] = useState();
  const [currentPlannerDay, setCurrentPlannerDay] = useState(null);
  const [showFullCalendarOnMobile, setShowFullCalendarOnMobile] = useState(false);
  /*const [showAllPlanned, setShowAllPlanned] = useState(false);
  const [showAllActual, setShowAllActual] = useState(false);*/


  const updatePlanned = (newPlan) => {
    var pendingCopy = pendingActivities ?? JSON.parse(JSON.stringify(activityDetail.activities));
    let newActivities = pendingCopy;

    for (var i = 0; i < newPlan.length; i++) {
      let value = newPlan[i].value;
      let time = newPlan[i].time;
      let type = newPlan[i].type;

      if (value === "") {
        value = 0;
      }
  
      value = parseFloat(value);
  
      if (value < 0) {
        value = 0;
      };
  
      var found = false;

      newActivities = newActivities.map(activity => {
        if (activity.Time === time && activity.IsPlanned) {
          found = true;
          if (type === "practice") {
            activity.PracticeValue = value;
          }
          else {
            activity.CompetitiveValue = value;
          }
          setCurrentPlannerDay(activity);
        }
        return activity;
      });
  
      if (!found) {
        let newActivity = { Time: time, IsPlanned: true, PracticeValue: 0, CompetitiveValue: 0 };
        if (type === "practice") {
          newActivity.PracticeValue = value;
        }
        else {
          newActivity.CompetitiveValue = value;
        }
        newActivities.push(newActivity);
        setCurrentPlannerDay(newActivity);
      }
    }


    setPendingActivities(newActivities.sort((a, b) => new Date(a.Time) - new Date(b.Time)));
  }

  const onPlannerClosed = () => {
    setPendingActivities(null);
  }

  const savePendingActivities = () => {
    var now = new Date();
    var nowDay = new Date();
    nowDay.setDate(now.getDate());
    nowDay.setHours(0, 0, 0, 0);
    let futurePlannedActivities = pendingActivities.filter((act) => act.IsPlanned && new Date(act.Time) >= nowDay);
    let activitiesToSave = futurePlannedActivities.map(act => {
      return {
        IsPlanned: true,
        Time: act.Time,
        PracticeValue: parseFloat(act.PracticeValue, 2) ?? 0.0,
        CompetitiveValue: parseFloat(act.CompetitiveValue, 2) ?? 0.0,
        UserActivityPubID: activityDetail.pub_id,
      }
    });

    props.onCreateActivities(activitiesToSave, activityDetail.pub_id);
    setPendingActivities(null);
    setShowFullCalendarOnMobile(false);
  }

  useEffect(() => {
    onFetchActivityDetail(activityID);
  }, [onFetchActivityDetail, activityID]);


  if (activityDetail.loading) {
    return (<PreLoader />)
  }


  // Days before today to show on the graph
  let preDays = activityDetail.pre_timeline_days;

  // Days after today to show on the graph
  let postDays = activityDetail.post_timeline_days;

  // Starting load
  let startingLoad = activityDetail.starting_load;

  let currentActivities = activityDetail.activities;
  if (pendingActivities) {
    currentActivities = pendingActivities;
  }

  let graphData = getGraphPoints(preDays, postDays, startingLoad, currentActivities);

  if (isAdding.dialogOpen) {
    return <div className="row">
      <AddActivity activityContext={activityDetail} defaults={isAdding.defaults} onAdd={(activityDate, practiceValue, competitiveValue, isPlanned, flag, comment) => {
        props.onCreateActivity(activityID, activityDate, practiceValue, competitiveValue, isPlanned, flag, comment)
      }
      } onClose={() => setIsAdding({ dialogOpen: false, defaults: null })} /></div>
  }

  if (isSharing) {
    return <div className="row">
      <ShareActivity activityContext={activityDetail} onShare={(shareToEmail, readOnly) => {
        props.onShareUserActivity(activityID, shareToEmail, readOnly);
      }
      } onClose={() => setIsSharing(false)} /></div>
  }

  if (settingsOpen) {
    return <div className="row"><AddUserActivity
      defaultName={activityDetail.name}
      defaultMeasure={activityDetail.measure_unit}
      defaultMeasureName={activityDetail.measure_unit_name}
      defaultPreTimelineDays={activityDetail.pre_timeline_days}
      defaultPostTimelineDays={activityDetail.post_timeline_days}
      defaultInitialLoad={activityDetail.starting_load}
      defaultTargetLoad={activityDetail.target_load}
      onAdd={(activityName, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) => { props.onUpdateUserActivity(activityID, activityName, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) }}
      onClose={() => setSettingsOpen(false)} /></div>
  }

  return (<>
    <div className="row" style={{ padding: "5px" }}>
      <div className="col-6">
        <BreadCrumb breadCrumbs={[{ title: "Home", url: "/" }, { title: activityDetail.name, url: `/activities/${activityID}` }]} />
      </div>

      {activityDetail.read_only ?
        <>
          <div className="col-2 col-sm-4"></div>

          <div className="col-4 col-sm-2">
            <div className=" alert alert-warning alert-outline" role="alert" style={{ height: '20px', padding: '0 0', marginBottom: '0' }}>
              Read only
            </div>
          </div>

        </> :
        <>
          <div className="d-none d-lg-block col-6">
            <button className="btn btn-primary has-icon mb-2 mr-2" title="Settings" onClick={() => setSettingsOpen(true)} ><i className="material-icons fs-16">settings</i> Settings</button>
            <button className="btn btn-primary has-icon mb-2 mr-2" title="Share" onClick={() => setIsSharing(true)}><i className="material-icons fs-16">share</i> Share</button>
            <button className="btn btn-success has_icon mb-2" onClick={() => setIsAdding({ dialogOpen: true, defaults: null })}><i className="material-icons fs-16">add</i> Add</button>
          </div>
          <div className="text-right d-block d-lg-none col-6 ">
            <button className="ms-btn-icon-outline btn-primary" title="Settings" onClick={() => setSettingsOpen(true)} ><i className="material-icons fs-16">settings</i></button> &nbsp;
            <button className="ms-btn-icon-outline btn-primary" title="Share" onClick={() => setIsSharing(true)}><i className="material-icons fs-16">share</i></button>
          </div>
        </>
      }
    </div>
    <div className="row px-0">

      {!showFullCalendarOnMobile && (<div className="col-lg-8 col-12 p-0" style={{ minHeight: '40vh' }}>
        <div className="ms-panel">
          {!activityDetail ? <div className="ms-panel-body text-center">No data</div> :
            <ActivityGraph data={graphData} />}
        </div>
      </div>)}

      <div className="col-lg-4 d-none d-lg-block">
        <div className="ms-panel">
          <div className="table-responsive">
            <Planner
              preDays={activityDetail.pre_timeline_days}
              postDays={activityDetail.post_timeline_days}
              activities={currentActivities}
              currentPlannerDay={currentPlannerDay}
              isDirty={pendingActivities ? true : false}
              onAddActivity={(defaults) => setIsAdding({ dialogOpen: true, defaults: defaults })}
              onClose={onPlannerClosed}
              onSave={savePendingActivities}
              updatePlanned={updatePlanned}
              currentChronic={graphData.todayChronic}
              readOnly={activityDetail.read_only} />
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-12 d-block d-lg-none p-0">
        <div className="ms-panel">
          <div className="table-responsive" style={{overflow: "hidden"}}>
            {!showFullCalendarOnMobile ?
              <button className="ms-btn-icon-outline btn-primary" style={{ position: "absolute", top: "2px", left: "20px", zIndex: "1000" }} title="Expand calendar" onClick={() => { setShowFullCalendarOnMobile(!showFullCalendarOnMobile) }}><i className="material-icons fs-16">calendar_month</i><i className="material-icons fs-16">north_east</i></button> :
              <button className="ms-btn-icon-outline btn-primary" style={{ position: "absolute", top: "2px", left: "20px", zIndex: "1000" }} title="Collapse calendar" onClick={() => { setShowFullCalendarOnMobile(!showFullCalendarOnMobile) }}><i className="material-icons fs-16">calendar_month</i><i className="material-icons fs-16">south_west</i></button>
            }

            <Planner
              preDays={activityDetail.pre_timeline_days}
              postDays={activityDetail.post_timeline_days}
              activities={currentActivities}
              currentPlannerDay={currentPlannerDay}
              isDirty={pendingActivities ? true : false}
              onAddActivity={(defaults) => setIsAdding({ dialogOpen: true, defaults: defaults })}
              onClose={onPlannerClosed}
              onSave={savePendingActivities}
              updatePlanned={updatePlanned}
              currentChronic={graphData.todayChronic}
              isMobile={!showFullCalendarOnMobile}
              readOnly={activityDetail.read_only} />
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default UserActivityView;
