import { Actions } from "../constants";


const userActivities = (state = {}, action) => {
  switch (action.type) {
    case Actions.ActivityDetail.SET_ACTIVITY_DETAIL_DATA:
      let setActivityDetailState = Object.assign({}, state);
      setActivityDetailState.loading = false;
      setActivityDetailState.pub_id = action.userActivityData.PubID;
      setActivityDetailState.name = action.userActivityData.Name;
      setActivityDetailState.measure_unit = action.userActivityData.MeasureUnit;
      setActivityDetailState.measure_unit_name = action.userActivityData.MeasureUnitName;
      setActivityDetailState.pre_timeline_days = action.userActivityData.TimeLinePreDays;
      setActivityDetailState.post_timeline_days = action.userActivityData.TimeLinePostDays;
      setActivityDetailState.starting_load = action.userActivityData.StartingLoad;
      setActivityDetailState.target_load = action.userActivityData.TargetLoad;
      setActivityDetailState.read_only = action.userActivityData.ReadOnly;
      setActivityDetailState.activities = action.userActivityData.Activities;
      setActivityDetailState.shares = action.userActivityData.Shares;
      return setActivityDetailState;

    case Actions.ActivityDetail.SET_USER_ACTIVITY_SHARE:
      console.log(action);
      let setUserActivityShareState = Object.assign({}, state);
      setUserActivityShareState.shares = action.shareInfo;
      return setUserActivityShareState;


    case Actions.ActivityDetail.FETCH_ACTIVITY_DETAIL:
    case Actions.ActivityDetail.CREATE_ACTIVITY:
    case Actions.ActivityDetail.CREATE_USER_ACTIVITIES:
    case Actions.UserInfo.UPDATE_USER_ACTIVITY:
      let fetchActivityDetailState = Object.assign({}, state);
      fetchActivityDetailState.loading = true;
      return fetchActivityDetailState;

    default:
      return state;
  }
}

export default userActivities
